<div fxLayout="row" class="prep-auth">
  <div fxLayout="column" class="page" fxFlex="55" fxHide.lt-md="true"></div>
  <div fxLayout="column" fxLayoutAlign="center center" style="background-color: rgb(255, 255, 255)" fxFlex="45"
    fxFlex.lt-md="100" class="elevation" fxLayoutGap="10px">
    <div fxFlex="20" fxLayoutAlign="start center" style="
        background-color: rgb(255, 255, 255);
        width: 100%;
        padding-left: 5%;
      ">
      <img src="../../../../assets/splash-screen/prep-icon.svg" height="90" />&nbsp;&nbsp;
      <span style="font-size: x-large; font-weight: bold">
        PDF Remediation Platform</span>
    </div>
    <div fxFlex="70" fxLayoutAlign="center center" style="width: 100%; padding-left: 5%; padding-right: 5%">
      <div [@fadeInUp] class="card border-radius" style="height: 60vh">
        <form [formGroup]="UserLoginFormGroup" (ngSubmit)="send()">
          <div fxLayout="column">
            <div style="padding-bottom: 30px">
              <img src="../../../../assets/Logo/miami-university-logo.png" height="90"> 
              <h1>USER LOGIN</h1>
              <!-- <h3 style="color: #136ef8">Welcome to PREP Console</h3> -->
            </div>
<!-- 
            <div fxFlex="auto" fxLayout="column">
              <mat-form-field fxFlex="grow" appearance="outline">
                <mat-label>E-Mail</mat-label>
                <input matInput required formControlName="username" id="username" />
              </mat-form-field>
              <mat-form-field fxFlex="grow" appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput [type]="inputType" formControlName="password" id="password" required minlength="6" />
                <button type="button" mat-icon-button matSuffix (click)="toggleVisibility()"
                  matTooltip="Toggle Visibility">
                  <mat-icon *ngIf="visible">visibility</mat-icon>
                  <mat-icon *ngIf="!visible">visibility_off</mat-icon>
                </button>
                <mat-hint>Click the eye to toggle visibility</mat-hint>
                <mat-error *ngIf="
                    UserLoginFormGroup.get('password').hasError('required')
                  ">We need a password to log you in
                </mat-error>
                <mat-error *ngIf="
                    UserLoginFormGroup.get('password').hasError('minlength')
                  ">The password should be minimum 6 characters</mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 15px 0">
              <mat-checkbox class="caption">remember me</mat-checkbox>

              <a class="caption" [routerLink]="['/forgot-password']">forgot password ?</a>
            </div> -->


            <button class="platform-login-button" type="submit" id="submit" color="primary" mat-raised-button
              [disabled]="UserLoginFormGroup.invalid || is_loading">
              SIGN IN
            </button>
            <br />

            <!-- <button class="platform-login-button" color="secondary" mat-raised-button (click)="clickRegisterNow()">
              REGISTER NOW
            </button> -->

            <br /><br />
            <small style="text-align: center;">Before clicking Log In you can review our company<br />
              <a style="text-decoration: underline;" href="https://prep.continualengine.com/privacy-policy/"
                target="_blank">Privacy
                Policy</a></small>


            <div fxLayout="row" fxLayoutAlign="center center" style="padding: 15px 0">
              <mat-spinner color="primary" [diameter]="40" fxLayout="row" fxLayoutAlign="center center"
                *ngIf="is_loading">
              </mat-spinner>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div fxFlex="10" fxLayoutAlign="center center" style="width: 100%; padding-left: 5%">
      <div fxLayout="column">
        <span><small>Powered by</small></span>
        <img src="../../../../assets/Logo/ce-logo.svg" height="50" />
      </div>
    </div>
  </div>
</div>